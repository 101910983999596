<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-50 dark:bg-darkbg-800">
    <div id="footer-heading" class="sr-only">
      Footer
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="py-20">
        <div
          class="grid grid-cols-1 md:grid-flow-col md:auto-rows-min md:grid-cols-12 md:gap-x-8 md:gap-y-16"
        >
          <div class="grid grid-cols-1 gap-6 md:col-span-4">
            <!-- Logo -->
            <div>
              <router-link to="/">
                <cgn-organisation-logo class="h-8 w-auto" />
              </router-link>
            </div>

            <router-link
              to="/contact"
              class="text-gray-500 hover:text-gray-600 dark:text-darkbg-50 dark:hover:text-darkbg-400"
            >
              Contact us
            </router-link>
            <router-link
              to="/terms-and-conditions"
              class="text-gray-500 hover:text-gray-600 dark:text-darkbg-50 dark:hover:text-darkbg-400"
            >
              T&C
            </router-link>
          </div>
          <!-- Sitemap sections -->
          <div class="mt-10 md:col-span-8 md:mt-0">
            <h3 class="text-sm font-medium text-gray-900 dark:text-darkbg-50 dark:hover:text-darkbg-400">
              Products
            </h3>
            <div class="mt-6 grid grid-flow-col grid-rows-4 gap-6 sm:grid-rows-3">
              <div v-for="shopGroup in shopGroups" :key="shopGroup.slug" class="text-sm">
                <router-link
                  :to="`/shop/${shopGroup.slug}`"
                  class="text-gray-500 hover:text-gray-600 dark:text-darkbg-50 dark:hover:text-darkbg-400"
                >
                  {{ shopGroup.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-100 py-10 text-center dark:border-gray-800">
        <p class="text-sm text-gray-500">
          &copy; {{ year }}. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'

const year = ref(new Date().getFullYear())
const shopGroups = ref<CognitoGroup[]>([])
onMounted(() => {
  new CognitoGroup().find_many({
    namespace: 'Sell',
    model: 'Product',
  }).then((data) => {
    shopGroups.value = data
  })
})
</script>
